/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;1,400;2,300&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Poppins:wght@300;400;500&display=swap");

/* global variables */
:root {
  --clr-primary: #007bfe;
  --clr-primary-light: #53a6ff;
  --clr-white: #ffffff;
  --clr-white-light: #f0f0f0;
  --clr-blue-light: #f9f9f957;
  --clr-blue-dark: #53a6ff5b;
  --clr-shadow: #00000029;
  --clr-grey: #f9f9f9;
  --clr-grey-text: #777777;
  --clr-black: #222222;
  --clr-black-30: rgba(34, 32, 32, 0.3);
  --clr-black-70: #222020b5;
  --clr-black-80: #222020cc;
  --clr-black-90: #212020e6;
  --clr-green-dark: hsl(125, 94%, 19%);
  --clr-green-light: hsl(126, 67%, 86%);
  --clr-red-dark: hsl(0, 80%, 35%);
  --clr-red-light: hsl(0, 67%, 89%);

  --transition: all 0.3s linear;
  --box-shadow-light: 0px 3px 7px var(--clr-shadow);
  --box-shadow-dark: 0px 3px 7px var(--clr-black-30);
  /* --font-family: "Open Sans", sans-serif; */
  --font-family: "Poppins", sans-serif;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --radius: 4px;
  --spacing: 1px;
}

/* Global Styles */
*,
::after,
::before {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

*:focus {
  outline-width: 4px;
  outline-offset: 2px;
  outline-color: var(--clr-primary);
}

body {
  color: var(--clr-black);
  background: var(--clr-white);
  font-family: var(--font-family);
  line-height: 1.5;
  font-weight: var(--font-weight-400);
  /* letter-spacing: 0.5px; */
}

.over-flow-hidden {
  overflow: hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  display: block;
}

hr {
  border: none;
  border-top: 1px solid var(--clr-grey-8);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-500);
}
form {
  all: unset;
}

/* global classes */
.wrapper {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.display-block {
  display: block !important;
}

.text-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.body-no-scroll {
  overflow: hidden;
}

.special-text-color {
  background: linear-gradient(180deg, #007bfe 0%, #53a6ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary-checkbox {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--clr-black);
  width: 24px;
  height: 24px;
  border: 1px solid var(--clr-white);
  background-color: var(--clr-white);
  border-radius: 6px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.primary-checkbox::before {
  content: "";
  width: 14px;
  height: 14px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--clr-primary);

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.primary-checkbox:checked::before {
  transform: scale(1);
}

.contactus-us-form-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.input-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3px;
}
.input-container .label-heading {
  font-size: 14px;
  line-height: 20px;
  color: var(--clr-white);
}
.input-container .label-heading.secundary {
  color: var(--clr-black);
}
.primary-input,
.primary-textarea {
  /* height: 45px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  border: 1px solid var(--clr-shadow);
  background: var(--clr-white);
  letter-spacing: 1px;
  */
  height: 45px;
  font-size: 14px;
  line-height: 20px;
  color: var(--clr-black);
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 14px;
  background: var(--clr-white);
  border: none;
  font-family: var(--font-family);
}
.primary-input.secundary,
.primary-textarea.secundary {
  color: var(--clr-black);
  background: var(--clr-blue-dark);
}
.primary-input:focus,
.primary-textarea:focus {
  outline: 1px solid var(--clr-primary);
}
@media (min-width: 992px) {
  .input-container .label-heading {
    font-size: 16px;
    line-height: 22px;
  }
  .primary-input {
    height: 50px;
    font-size: 16px;
    line-height: 22px;
  }
}
.side-by-side {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
@media screen and (min-width: 992px) {
  .side-by-side {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
.primary-input:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.primary-textarea {
  height: unset;
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 150px;
  resize: vertical;
}
.primary-select {
  /* font-size: 16px;
  height: 45px;
  line-height: 25px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 2px;
  border: 1px solid var(--clr-shadow);
  background: var(--clr-white);
  letter-spacing: 1px;
  font-family: var(--font-family);
  text-transform: capitalize; */

  height: 45px;
  font-size: 14px;
  line-height: 20px;
  color: var(--clr-black);
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 14px;
  background: var(--clr-white);
  border: none;
  font-family: var(--font-family);
}
.primary-select:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
@media (min-width: 992px) {
  .primary-select {
    height: 50px;
    font-size: 16px;
    line-height: 22px;
  }
}
.select-main-myform {
  position: relative;
  width: 100%;
}
.select-main-myform select {
  appearance: none;
  width: 100%;
  padding-right: 35px;
}
.select-main-myform .select-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.select-main-myform .select-icon svg {
  font-size: 20px;
}
@media screen and (min-width: 992px) {
  .select-main .select-icon svg {
    font-size: 26px;
  }
}

.primary-button {
  height: 45px;
  min-width: 130px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding-left: 24px;
  padding-right: 24px;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: var(--font-weight-500);
  color: var(--clr-white);
  background: linear-gradient(var(--clr-primary), var(--clr-primary-light));
  border: 1px solid var(--clr-primary);
}
.primary-button.secundary {
  color: var(--clr-primary);
  background: var(--clr-white);
  border: none;
}
.primary-button:disabled {
  cursor: not-allowed;
  opacity: 40%;
}
@media screen and (min-width: 992px) {
  .primary-button {
    height: 50px;
    font-size: 16px;
  }
}
@media screen and (max-width: 991px) {
  .form-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 991px) {
  .full-width-mobile {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .full-width-desktop {
    width: 100%;
  }
}

.page-100 {
  min-height: calc(100vh - 13vh);
  padding: 5rem 0;
}

.page {
  min-height: calc(100vh - (20vh + 10rem));
}

@media screen and (min-width: 768px) {
  .page-100 {
    min-height: calc(100vh - 15vh);
  }
}

/* padding classes */
@media screen and (max-width: 991px) {
  .paddingTopMobile-ten {
    padding-top: 10px !important;
  }

  .paddingTopMobile-twenty {
    padding-top: 20px !important;
  }

  .paddingTopMobile-thirty {
    padding-top: 30px !important;
  }

  .paddingTopMobile-forty {
    padding-top: 40px !important;
  }

  .paddingTopMobile-fifty {
    padding-top: 50px !important;
  }

  .paddingTopMobile-sixty {
    padding-top: 60px !important;
  }

  .paddingTopMobile-seventy {
    padding-top: 70px !important;
  }

  .paddingTopMobile-eighty {
    padding-top: 80px !important;
  }

  .paddingTopMobile-ninety {
    padding-top: 90px !important;
  }

  .paddingTopMobile-hundred {
    padding-top: 100px !important;
  }

  .paddingBottomMobile-ten {
    padding-bottom: 10px !important;
  }

  .paddingBottomMobile-twenty {
    padding-bottom: 20px !important;
  }

  .paddingBottomMobile-thirty {
    padding-bottom: 30px !important;
  }

  .paddingBottomMobile-forty {
    padding-bottom: 40px !important;
  }

  .paddingBottomMobile-fifty {
    padding-bottom: 50px !important;
  }

  .paddingBottomMobile-sixty {
    padding-bottom: 60px !important;
  }

  .paddingBottomMobile-seventy {
    padding-bottom: 70px !important;
  }

  .paddingBottomMobile-eighty {
    padding-bottom: 80px !important;
  }

  .paddingBottomMobile-ninety {
    padding-bottom: 90px !important;
  }

  .paddingBottomMobile-hundred {
    padding-bottom: 100px !important;
  }

  .mwh-130 {
    width: 130px;
    height: 130px;
  }

  .mwh-200 {
    width: 200px;
    height: 200px;
  }

  .mwh-180 {
    width: 180px;
    height: 180px;
  }

  .mwh-160 {
    width: 160px;
    height: 160px;
  }

  .mwh-125 {
    width: 125px;
    height: 125px;
  }

  .mwh-190 {
    width: 190px;
    height: 190px;
  }
}

@media screen and (min-width: 992px) {
  .paddingTopDesktop-ten {
    padding-top: 10px !important;
  }

  .paddingTopDesktop-twenty {
    padding-top: 20px !important;
  }

  .paddingTopDesktop-thirty {
    padding-top: 30px !important;
  }

  .paddingTopDesktop-forty {
    padding-top: 40px !important;
  }

  .paddingTopDesktop-fifty {
    padding-top: 50px !important;
  }

  .paddingTopDesktop-sixty {
    padding-top: 60px !important;
  }

  .paddingTopDesktop-seventy {
    padding-top: 70px !important;
  }

  .paddingTopDesktop-eighty {
    padding-top: 80px !important;
  }

  .paddingTopDesktop-ninety {
    padding-top: 90px !important;
  }

  .paddingTopDesktop-hundred {
    padding-top: 100px !important;
  }

  .paddingBottomDesktop-ten {
    padding-bottom: 10px !important;
  }

  .paddingBottomDesktop-twenty {
    padding-bottom: 20px !important;
  }

  .paddingBottomDesktop-thirty {
    padding-bottom: 30px !important;
  }

  .paddingBottomDesktop-forty {
    padding-bottom: 40px !important;
  }

  .paddingBottomDesktop-fifty {
    padding-bottom: 50px !important;
  }

  .paddingBottomDesktop-sixty {
    padding-bottom: 60px !important;
  }

  .paddingBottomDesktop-seventy {
    padding-bottom: 70px !important;
  }

  .paddingBottomDesktop-eighty {
    padding-bottom: 80px !important;
  }

  .paddingBottomDesktop-ninety {
    padding-bottom: 90px !important;
  }

  .paddingBottomDesktop-hundred {
    padding-bottom: 100px !important;
  }

  .dwh-175 {
    width: 175px;
    height: 175px;
  }

  .dwh-185 {
    width: 185px;
    height: 185px;
  }

  .dwh-260 {
    width: 260px;
    height: 260px;
  }

  .dwh-215 {
    width: 215px;
    height: 215px;
  }

  .dwh-245 {
    width: 245px;
    height: 245px;
  }

  .dwh-180 {
    width: 180px;
    height: 180px;
  }

  .dwh-280 {
    width: 280px;
    height: 280px;
  }
}
